@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Material+Icons&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Material+Icons&display=swap");
@import '~highlight.js/styles/github.css';
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Material+Icons&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Material+Icons&display=swap");
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/* Foreground Elements Light Theme Text Copied over from @angular/material. These use to be exported by the library, but are no longer. */
/* Dark Theme text */
/* Background config Light bg */
body {
  --primary-color: #2196f3;
  --primary-lighter-color: #9bcff9;
  --primary-darker-color: #1479ed;
  --primary-darkest-color: #0e5dc7;
  --primary-darkest-darker-color: #0a47a2;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(0, 0, 0, 0.8);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: #ff9800;
  --accent-lighter-color: #ffe0b2;
  --accent-darker-color: #ff7b00;
  --accent-darkest-color: #e66a00;
  --text-accent-color: rgba(0, 0, 0, 0.8);
  --text-accent-lighter-color: rgba(0, 0, 0, 0.8);
  --text-accent-darker-color: rgba(0, 0, 0, 0.8);
}

body {
  --tertiary-color: #4caf50;
  --tertiary-lighter-color: #80e27e;
  --tertiary-darker-color: #388e3c;
  --text-tertiary-color: rgba(0, 0, 0, 0.8);
  --text-tertiary-lighter-color: rgba(0, 0, 0, 0.8);
  --text-tertiary-darker-color: rgba(0, 0, 0, 0.8);
}

body {
  --quaternary-color: #951ed9;
  --quaternary-lighter-color: #d097f0;
  --quaternary-darker-color: #8d1cce;
  --text-quaternary-color: rgba(0, 0, 0, 0.8);
  --text-quaternary-lighter-color: rgba(0, 0, 0, 0.8);
  --text-quaternary-darker-color: rgba(0, 0, 0, 0.8);
}

body {
  --warn-color: #f44336;
  --warn-lighter-color: #fcc7c3;
  --warn-darker-color: #ef2c22;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.8);
  --text-warn-darker-color: #ffffff;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  height: 100%;
}

ol {
  padding-inline-start: 1rem;
}

ul {
  padding-inline-start: 1rem;
}

p,
ol,
ul {
  margin-block-start: 0;
  margin-block-end: 0.5em;
}

p:last-child,
ol:last-child,
ul:last-child {
  margin-block-end: 0;
}

a:hover {
  color: var(--accent-darker-color);
  transition: color 0.25s ease;
}

/* Page content */
.container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding-top: 5px;
}

.partial-width {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

.full-height {
  box-sizing: border-box;
  height: calc(100vh - 64px);
  overflow: hidden;
}
.full-height.has-breadcrumbs {
  height: calc(100vh - 64px - 28px - 0.5rem);
}

@media screen and (max-width: 959px) {
  .full-height {
    height: calc(100vh - 56px);
  }
  .full-height.has-breadcrumbs {
    height: calc(100vh - 56px - 28px - 0.5rem);
  }
}
.padded {
  padding: 2rem;
}

@media screen and (max-width: 959px) {
  .padded {
    padding: 1rem;
  }
}
.horz-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vert-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1,
.h1 {
  /*  @include mat.typography-level($fontConfig, headline); */
  line-height: 1.6rem;
  margin-top: 0;
  color: #2196f3;
  border-bottom: 1px solid #2196f3;
}
h1 a,
.h1 a {
  color: #2196f3;
  text-decoration: none;
}

h2,
.h2 {
  /* @include mat.typography-level($fontConfig, title); */
  color: #2196f3;
}

.inline-list {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.inline-list > *:after:not(:last-child) {
  content: ", ";
}

.icon-list .heading {
  font-weight: bold;
}

ul.icon-list {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0.5rem;
  padding-inline-start: 0;
}
ul.icon-list:last-child {
  margin-block-end: 0;
}
ul.icon-list > li {
  margin-left: calc(12px + 0.25rem);
  text-indent: calc(-1 * (12px + 0.25rem));
}
ul.icon-list ul {
  list-style-type: initial;
}
ul.icon-list ul li {
  margin-left: 0.75rem;
  text-indent: initial;
}
ul.icon-list biosimulations-icon {
  margin-right: 0.3rem;
}
ul.icon-list .mat-icon-button {
  border-radius: 0;
}

table.icon-list {
  border-spacing: 0;
  padding: 0;
}
table.icon-list th:first-child {
  vertical-align: top;
  padding-right: 0.125rem;
  text-align: center;
}
table.icon-list th:first-child biosimulations-icon .mat-icon {
  font-size: 112%;
  line-height: normal;
  position: relative;
  top: 2px;
}
table.icon-list a {
  color: rgba(0, 0, 0, 0.8);
}
table.icon-list a:hover {
  color: #2196f3;
}

.vertically-spaced > li:not(:first-child) {
  margin-top: 0.5rem;
}

ul .vertically-spaced > li:not(:first-child) {
  margin-top: 0.25rem;
}

ul.key-value li a,
ul.key-value li .mat-icon-button.biosimulations-button.primary {
  color: rgba(0, 0, 0, 0.8);
}
ul.key-value li a:hover,
ul.key-value li .mat-icon-button.biosimulations-button.primary:hover {
  color: #2196f3;
}

a {
  color: #2196f3;
  text-decoration: none;
  cursor: pointer;
}
a.text {
  color: rgba(0, 0, 0, 0.8);
}
a.text:hover {
  color: #2196f3;
}

.highlight-primary {
  color: #2196f3;
}

.highlight-accent {
  color: #ff9800;
}

.zoom {
  transition: all 0.5s;
}

.zoom:hover {
  transform: scale(1.08);
}

.hidden {
  display: none !important;
}

.hanging-indent {
  text-indent: -1.1em;
  margin-left: 1.1em;
}

.mat-typography p:not(:last-child) {
  margin-bottom: 0.5rem;
}
.mat-typography p:last-child, .mat-typography p.no-bottom-margin {
  margin-bottom: 0;
}
.mat-typography p.no-bottom-margin {
  margin-bottom: 0;
}

ul.comma-separated {
  list-style-type: none;
  padding-inline-start: 0;
}
ul.comma-separated li {
  display: inline-block;
}
ul.comma-separated li:not(:nth-last-child(1)):not(:nth-last-child(2))::after {
  content: ",";
  margin-right: 0.25rem;
}
ul.comma-separated li:nth-last-child(2)::after {
  content: ",";
  margin-right: 0.25rem;
}
ul.comma-separated.no-wrap li {
  white-space: nowrap;
}
ul.comma-separated.ampersand-separator li:nth-last-child(2)::after {
  content: " &";
  margin-right: 0.25rem;
}
ul.comma-separated.and-separator li:nth-last-child(2)::after {
  content: " and";
  margin-right: 0.25rem;
}
ul.comma-separated.or-separator li:nth-last-child(2)::after {
  content: " &";
  margin-right: 0.25rem;
}

/* forms */
biosimulations-root .mat-form-field:not(.card-form-field) {
  display: block;
  letter-spacing: normal;
}
biosimulations-root .mat-form-field:not(.card-form-field):last-child .mat-form-field-wrapper {
  padding-bottom: 0;
}
biosimulations-root .mat-form-field-appearance-fill:not(.card-form-field) .mat-form-field-flex {
  padding: 0.4em;
  background-color: #f2f2f2;
  align-items: center;
  height: 41.5px;
}
biosimulations-root .mat-form-field-appearance-fill:not(.card-form-field) .mat-form-field-infix {
  padding: 0;
  padding-top: 0.25em;
  width: auto;
}
biosimulations-root .mat-form-field-appearance-fill:not(.card-form-field) .mat-form-field-underline {
  display: none;
}
biosimulations-root .mat-form-field-appearance-fill:not(.card-form-field) .mat-form-field-subscript-wrapper {
  margin-top: 0;
  padding: 0.4em 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.42);
  position: unset;
}
biosimulations-root .mat-form-field-appearance-fill:not(.card-form-field).mat-form-field-disabled .mat-form-field-flex {
  background-color: #cccccc;
}
biosimulations-root .mat-form-field-prefix biosimulations-icon {
  font-size: 24px;
  margin-right: 0.5rem;
  color: white;
  line-height: 0;
}
biosimulations-root .mat-form-field-suffix {
  display: flex;
  align-items: center;
  height: 100%;
}
biosimulations-root .mat-form-field-suffix biosimulations-icon {
  font-size: 16px;
  margin-left: 0.5rem;
  line-height: 0;
}
biosimulations-root .ngx-mat-file-input {
  height: 15px;
  line-height: 15px;
}
biosimulations-root .ngx-mat-file-input .mat-icon-button.mat-primary {
  color: rgba(0, 0, 0, 0.8);
}
biosimulations-root .mat-form-field:not(.mat-form-field-appearance) .ngx-mat-file-input .mat-form-field-suffix .mat-icon-button {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.2rem;
}
biosimulations-root form .mat-flat-button {
  padding: 0 0.75em;
  background-color: #e6e6e6;
}
biosimulations-root .form-header {
  padding-bottom: 2rem;
  font-size: 125%;
  line-height: 1.3;
}
biosimulations-root .mat-hint {
  font-size: 125%;
  line-height: 1.3;
}
biosimulations-root .mat-checkbox-ripple {
  display: none;
}

.card-form-section {
  overflow: auto;
}
.card-form-section:not(:last-child) {
  margin-bottom: 2rem;
}
.card-form-section mat-card-title {
  margin-bottom: 1.2rem;
}
.card-form-section mat-checkbox {
  display: block;
}
.card-form-section mat-error {
  font-size: 125%;
  line-height: 1.3;
  margin-top: 0.6rem;
}
.card-form-section .error-border {
  border-bottom-color: #f44336;
}
.card-form-section button {
  font-size: 150%;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 0.9rem;
}
.card-form-section button.primary {
  margin-left: auto;
}
.card-form-section .input-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -0.3rem;
}
.card-form-section .input-group mat-form-field {
  flex: 1;
  margin: 0 0.3rem;
  min-width: 25rem;
}
.card-form-section .input-group mat-hint {
  margin: 0 0.3rem;
}
.card-form-section .input-group .input-prefix {
  font-size: 175%;
}

.form-section {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.form-section:not(:last-child) {
  margin-bottom: 2rem;
}
.form-section .form-section-head {
  padding: 0.4em;
  background-color: var(--primary-darker-color) !important;
  height: calc(41.5px - 0.8rem);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
}
.form-section .form-section-head-title-subtitle {
  flex-grow: 1;
}
.form-section .form-section-title {
  line-height: 100%;
  padding-bottom: 3.5px;
}
.form-section .form-section-subtitle {
  font-size: 75%;
  color: rgba(0, 0, 0, 0.6);
  line-height: 100%;
}
.form-section .form-section-body {
  padding: 0.5rem;
}
.form-section .form-field-group:not(:last-child) {
  padding-bottom: 1rem;
}
.form-section .form-field-group .columns {
  display: grid;
  grid-gap: 0.5rem;
}
.form-section .form-field-group .two-columns {
  grid-template-columns: 1fr 1fr;
}
.form-section .form-field-group .three-columns {
  grid-template-columns: 1fr 1fr 1fr;
}
.form-section .form-field-group .four-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.form-section .form-field-group .five-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.form-section .form-field-group .six-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.form-section .form-field-group .seven-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.form-section .form-field-group .eight-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.form-section .form-field-group .nine-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.form-section .form-field-group .ten-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.form-section .form-field-group .eleven-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.form-section .form-field-group .twelve-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.form-section .form-field-group .mat-form-field-wrapper {
  padding-bottom: 0;
}
.form-section .form-field-group .mat-form-field-subscript-wrapper {
  padding-top: 0;
}
.form-section .form-field-group mat-hint,
.form-section .form-field-group mat-error {
  padding-top: 0.4em;
  font-size: 93.75%;
  display: block;
}
.form-section .form-field-group mat-hint:first-child,
.form-section .form-field-group mat-error:first-child {
  padding-top: 0;
}
.form-section .form-field-group mat-hint code,
.form-section .form-field-group mat-hint code.hljs,
.form-section .form-field-group mat-error code,
.form-section .form-field-group mat-error code.hljs {
  padding: 0.15rem;
}
.form-section.form-section-closed .form-section-head {
  border-bottom: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.form-section.form-section-closed .form-section-body {
  display: none;
}

.icon-label-input {
  padding: 0.4em 0.4em 0;
  background-color: #f2f2f2;
  border-radius: 4px 4px 0 0;
  display: flex;
}
.icon-label-input > biosimulations-icon {
  position: relative;
  top: 0.4rem;
  margin-right: 0.5rem;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.7);
}
.icon-label-input .label-input {
  width: 100%;
}
.icon-label-input mat-label,
.icon-label-input .input-heading {
  font-size: 75%;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1;
  font-weight: normal;
  text-align: center;
}
.icon-label-input:focus mat-label {
  color: #2196f3;
}
.icon-label-input .input {
  padding: 0.25em 0 0.75em;
  width: 100%;
  display: grid;
  grid-column-gap: 0.5em;
}
.icon-label-input .input input,
.icon-label-input .input .mat-select {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.6);
  background: #e6e6e6;
  font-size: 93.75%;
}
.icon-label-input .input input:disabled,
.icon-label-input .input .mat-select:disabled {
  background: #cccccc;
}
.icon-label-input .input input.error-border,
.icon-label-input .input .mat-select.error-border {
  border-bottom-color: #f44336;
}
.icon-label-input .input .mat-select {
  position: relative;
  top: -1px;
}
.icon-label-input .input-row {
  padding-top: 0.25em;
}
.icon-label-input .input-label {
  font-size: 93.75%;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon-label-input mat-hint {
  padding-top: 0.4rem;
  border-top: 1px solid rgba(0, 0, 0, 0.42);
  font-size: 93.75%;
  display: block;
}
.icon-label-input mat-hint:first-child {
  padding-top: 0;
}
.icon-label-input mat-hint code,
.icon-label-input mat-hint code.hljs {
  padding: 0.15rem;
}

.form-button-container {
  text-align: right;
}

.cdk-overlay-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto;
  letter-spacing: 0.0179em;
}

.mat-select-panel {
  margin-top: 26px;
  margin-left: -6px;
}

/* code highlighting */
pre {
  padding: 0.5rem;
  margin: 0;
  border-radius: 4px;
  overflow-x: auto;
}
pre:not(:last-child) {
  margin-bottom: 0.5rem;
}
pre code,
pre code.hljs {
  padding: 0;
}

code,
code.hljs {
  padding: 0.25rem;
  display: inline;
}

code,
code.hljs,
pre {
  font-size: 90%;
  background: #f7f7f7;
  color: rgba(0, 0, 0, 0.8);
}

pre.dark,
pre.dark code {
  /* `{prefix of prefixes}-{color of colors}-{ground of grounds}`; */
  /* color=[black, red,  green, green, yellow, blue, magenta, cyan, white] */
  /* ground=[fg, bg] */
  /* prefix=[ansi-light, ansi */
  background: #2c2c2c;
  color: #ffffff;
}
pre.dark .ansi-black-fg,
pre.dark code .ansi-black-fg {
  color: #000000;
}
pre.dark .ansi-red-fg,
pre.dark code .ansi-red-fg {
  color: #ff0000;
}
pre.dark .ansi-green-fg,
pre.dark code .ansi-green-fg {
  color: #00ff00;
}
pre.dark .ansi-yellow-fg,
pre.dark code .ansi-yellow-fg {
  color: #ffff00;
}
pre.dark .ansi-blue-fg,
pre.dark code .ansi-blue-fg {
  color: #0000ff;
}
pre.dark .ansi-magenta-fg,
pre.dark code .ansi-magenta-fg {
  color: #ff00ff;
}
pre.dark .ansi-cyan-fg,
pre.dark code .ansi-cyan-fg {
  color: #00ffff;
}
pre.dark .ansi-white-fg,
pre.dark code .ansi-white-fg {
  color: #ffffff;
}
pre.dark .ansi-bright-black-fg,
pre.dark code .ansi-bright-black-fg {
  color: #7f7f7f;
}
pre.dark .ansi-bright-red-fg,
pre.dark code .ansi-bright-red-fg {
  color: #ff0000;
}
pre.dark .ansi-bright-green-fg,
pre.dark code .ansi-bright-green-fg {
  color: #00ff00;
}
pre.dark .ansi-bright-yellow-fg,
pre.dark code .ansi-bright-yellow-fg {
  color: #ffff00;
}
pre.dark .ansi-bright-blue-fg,
pre.dark code .ansi-bright-blue-fg {
  color: #0000ff;
}
pre.dark .ansi-bright-magenta-fg,
pre.dark code .ansi-bright-magenta-fg {
  color: #ff00ff;
}
pre.dark .ansi-bright-cyan-fg,
pre.dark code .ansi-bright-cyan-fg {
  color: #00ffff;
}
pre.dark .ansi-bright-white-fg,
pre.dark code .ansi-bright-white-fg {
  color: #ffffff;
}
pre.dark .ansi-black-bg,
pre.dark code .ansi-black-bg {
  background-color: #000000;
}
pre.dark .ansi-red-bg,
pre.dark code .ansi-red-bg {
  background-color: #ff0000;
}
pre.dark .ansi-green-bg,
pre.dark code .ansi-green-bg {
  background-color: #00ff00;
}
pre.dark .ansi-yellow-bg,
pre.dark code .ansi-yellow-bg {
  background-color: #ffff00;
}
pre.dark .ansi-blue-bg,
pre.dark code .ansi-blue-bg {
  background-color: #0000ff;
}
pre.dark .ansi-magenta-bg,
pre.dark code .ansi-magenta-bg {
  background-color: #ff00ff;
}
pre.dark .ansi-cyan-bg,
pre.dark code .ansi-cyan-bg {
  background-color: #00ffff;
}
pre.dark .ansi-white-bg,
pre.dark code .ansi-white-bg {
  background-color: #ffffff;
}
pre.dark .ansi-bright-black-bg,
pre.dark code .ansi-bright-black-bg {
  background-color: #7f7f7f;
}
pre.dark .ansi-bright-red-bg,
pre.dark code .ansi-bright-red-bg {
  background-color: #ff0000;
}
pre.dark .ansi-bright-green-bg,
pre.dark code .ansi-bright-green-bg {
  background-color: #00ff00;
}
pre.dark .ansi-bright-yellow-bg,
pre.dark code .ansi-bright-yellow-bg {
  background-color: #ffff00;
}
pre.dark .ansi-bright-blue-bg,
pre.dark code .ansi-bright-blue-bg {
  background-color: #0000ff;
}
pre.dark .ansi-bright-magenta-bg,
pre.dark code .ansi-bright-magenta-bg {
  background-color: #ff00ff;
}
pre.dark .ansi-bright-cyan-bg,
pre.dark code .ansi-bright-cyan-bg {
  background-color: #00ffff;
}
pre.dark .ansi-bright-white-bg,
pre.dark code .ansi-bright-white-bg {
  background-color: #ffffff;
}

.code {
  font-family: monospace;
}

.ansi-black {
  color: #000;
}

.ansi-red {
  color: 187, 0, 0;
}

.ansi-green {
  color: 0, 187, 0;
}

.ansi-yellow {
  color: 187, 187, 0;
}

.ansi-blue {
  color: 0, 0, 187;
}

.ansi-magenta {
  color: 187, 0, 187;
}

pre.stdout.dark.ng-star-inserted ::ng-deep {
  color: 0, 187, 187;
}

.ansi-cyan-fg ::ng-deep {
  color: 0, 187, 187;
}

.ansi-white {
  color: 255, 255, 255;
}

.ansi-bright-black {
  color: 85, 85, 85;
}

.ansi-bright-red {
  color: 255, 85, 85;
}

.ansi-bright-green {
  color: 85, 255, 85;
}

.ansi-bright-yellow {
  color: 255, 255, 85;
}

.ansi-bright-blue {
  color: 85, 85, 255;
}

.ansi-bright-magenta {
  color: 255, 85, 255;
}

.ansi-bright-cyan {
  color: 85, 255, 255;
}

.ansi-bright-white {
  color: 255, 255, 255;
}

.ansi-bg-black {
  background-color: #000;
}

/* range slider */
/* TODO move to its component css */
biosimulations-slider .slider .bar > span.left-handle, biosimulations-slider .slider .bar > span.right-handle {
  border-color: #2196f3 !important;
  background: #9bcff9 !important;
}
biosimulations-slider .slider .bar > span.left-handle .handle-tooltip, biosimulations-slider .slider .bar > span.right-handle .handle-tooltip {
  border-color: #2196f3 !important;
  background: #9bcff9 !important;
  color: #2196f3 !important;
  font-weight: bold;
}
biosimulations-slider .slider .bar div.filler {
  border-color: #e6e6e6 !important;
}
biosimulations-slider .slider .bar div.filler > span {
  background: #9bcff9 !important;
}
biosimulations-slider .slider .values {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

/* buttons */
.mat-icon-button.biosimulations-button {
  width: auto;
  height: auto;
  line-height: unset;
}
.mat-icon-button.biosimulations-button.text {
  color: rgba(0, 0, 0, 0.8);
}
.mat-icon-button.biosimulations-button.text:hover {
  color: #2196f3;
}
.mat-icon-button.biosimulations-button.text:active {
  color: #1479ed;
}
.mat-icon-button.biosimulations-button.primary {
  color: #2196f3;
}
.mat-icon-button.biosimulations-button.primary:hover {
  color: #0d8aee;
}
.mat-icon-button.biosimulations-button.primary:active {
  color: #0c7cd5;
}
.mat-icon-button.biosimulations-button.accent {
  color: #ff9800;
}
.mat-icon-button.biosimulations-button.accent:hover {
  color: #e68900;
}
.mat-icon-button.biosimulations-button.accent:active {
  color: #cc7a00;
}
.mat-icon-button.biosimulations-button.white {
  color: #ffffff;
}
.mat-icon-button.biosimulations-button.white:hover {
  color: rgba(255, 255, 255, 0.8666666667);
}
.mat-icon-button.biosimulations-button.white:active {
  color: rgba(255, 255, 255, 0.7333333333);
}
.mat-icon-button.biosimulations-button .mat-button-focus-overlay {
  display: none;
}

.mat-flat-button.biosimulations-button.text {
  color: rgba(0, 0, 0, 0.8);
  background: #e6e6e6;
}
.mat-flat-button.biosimulations-button.text:hover {
  background: #dbdbdb;
}
.mat-flat-button.biosimulations-button.text:active {
  background: #d1d1d1;
}
.mat-flat-button.biosimulations-button.primary {
  color: #2196f3;
  background: #d2eafc;
}
.mat-flat-button.biosimulations-button.primary:hover {
  background: #bfe1fb;
}
.mat-flat-button.biosimulations-button.primary:active {
  background: #acd8f9;
}
.mat-flat-button.biosimulations-button.accent {
  color: #ff9800;
  background: #fff4e5;
}
.mat-flat-button.biosimulations-button.accent:hover {
  background: #ffebd1;
}
.mat-flat-button.biosimulations-button.accent:active {
  background: #ffe3bc;
}
.mat-flat-button.biosimulations-button.white {
  color: #ffffff;
}
.mat-flat-button.biosimulations-button.white:hover {
  color: rgba(255, 255, 255, 0.8666666667);
}
.mat-flat-button.biosimulations-button.white:active {
  color: rgba(255, 255, 255, 0.7333333333);
}
.mat-flat-button.biosimulations-button .mat-button-focus-overlay {
  display: none;
}

/* tables */
table:not(:last-child) {
  margin-bottom: 0.5rem;
}

.biosimulations-table {
  width: 100%;
}
.biosimulations-table:not(:last-child) {
  margin-bottom: 0.5rem;
}
.biosimulations-table thead tr {
  background: #d2eafc;
  height: 32px;
}
.biosimulations-table tbody tr:nth-child(even) {
  background: #f7f7f7;
}
.biosimulations-table tbody tr:nth-child(odd) {
  background: #fcfcfc;
}
.biosimulations-table tbody tr:hover {
  background: #e6e6e6;
}
.biosimulations-table tbody td {
  vertical-align: top;
}
.biosimulations-table th,
.biosimulations-table td {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-align: left;
}
.biosimulations-table.rotated th {
  background: #e6e6e6;
}
.biosimulations-table.rotated td:nth-child(even) {
  background: #f7f7f7;
}
.biosimulations-table.rotated td:nth-child(odd) {
  background: #fcfcfc;
}
.biosimulations-table .mat-icon-button {
  display: inline;
  white-space: normal;
}

.mat-table.biosimulations-table {
  width: auto;
  min-width: min-content;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: transparent;
}
.mat-table.biosimulations-table:not(:last-child) {
  margin-bottom: 0.5rem;
}
.mat-table.biosimulations-table .mat-header-row {
  background: #e6e6e6;
  min-height: 32px;
}
.mat-table.biosimulations-table .mat-sort-header-arrow {
  color: #2196f3;
  transform: scale(1.35, 1.35) !important;
}
.mat-table.biosimulations-table mat-row:nth-child(even) {
  background: #f7f7f7;
}
.mat-table.biosimulations-table mat-row:nth-child(odd) {
  background: #fcfcfc;
}
.mat-table.biosimulations-table .mat-row {
  min-height: 28px;
}
.mat-table.biosimulations-table .mat-row:hover {
  background: #e6e6e6;
}
.mat-table.biosimulations-table .highlight-row {
  background: #e6e6e6;
}
.mat-table.biosimulations-table .mat-cell,
.mat-table.biosimulations-table .mat-header-cell {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto;
  letter-spacing: 0.0179em;
}
.mat-table.biosimulations-table .mat-cell:not(:first-of-type),
.mat-table.biosimulations-table .mat-header-cell:not(:first-of-type) {
  padding-left: 0.125rem;
  border-left: 1px solid #cccccc;
}
.mat-table.biosimulations-table .mat-cell:not(:last-of-type),
.mat-table.biosimulations-table .mat-header-cell:not(:last-of-type) {
  padding-right: 0.125rem;
}
.mat-table.biosimulations-table .mat-cell:first-of-type,
.mat-table.biosimulations-table .mat-header-cell:first-of-type {
  padding-left: 0.125rem;
}
.mat-table.biosimulations-table .mat-cell:last-of-type,
.mat-table.biosimulations-table .mat-header-cell:last-of-type {
  padding-right: 0.125rem;
}
.mat-table.biosimulations-table .mat-header-cell {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}
.mat-table.biosimulations-table .mat-cell {
  color: rgba(0, 0, 0, 0.8);
}
.mat-table.biosimulations-table .mat-cell *:focus {
  outline: none;
}
.mat-table.biosimulations-table .cell-content-container {
  width: 100%;
  white-space: unset;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.mat-table.biosimulations-table .cell-content-container.center {
  text-align: center;
}

.clean-table {
  width: 100%;
  display: grid;
  grid-gap: 0;
}
.clean-table .head {
  font-weight: bold;
}
.clean-table .cell {
  border-bottom: 1px dotted #cccccc;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 18px;
  vertical-align: middle;
}
.clean-table .cell:not(:first-child) {
  padding-left: 0.25rem;
}
.clean-table .cell:not(:last-child) {
  padding-right: 0.25rem;
}
.clean-table .icon-container {
  width: 16px;
  margin-right: 0.25rem;
  display: inline-flex;
  justify-content: center;
}
.clean-table biosimulations-icon .mat-icon {
  font-size: 112%;
  line-height: normal;
  position: relative;
  top: 2px;
}
.clean-table a {
  color: rgba(0, 0, 0, 0.8);
}
.clean-table a:hover {
  text-decoration: underline;
}

/* section */
.border {
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.clear-float {
  clear: both;
}

.wide-side-bar {
  width: 26rem;
}

.compact-sections {
  margin-top: 1rem;
}

/* content */
.no-wrap {
  white-space: nowrap;
}

.info-message {
  font-style: italic;
}

/* tooltip */
.mat-tooltip {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  line-height: 135%;
}

/* columns */
.ragged-column {
  height: max-content;
}

/* status colors */
.status-label.CREATED, .status-label.QUEUED {
  color: #2196f3;
}
.status-label.RUNNING, .status-label.PROCESSING {
  color: #4caf50;
}
.status-label.SUCCEEDED {
  color: rgba(0, 0, 0, 0.8);
}
.status-label.SKIPPED {
  color: #ff9800;
}
.status-label.FAILED {
  color: #f44336;
}

a.status-label.CREATED:hover, a.status-label.QUEUED:hover {
  color: #0c7cd5;
}
a.status-label.CREATED:active, a.status-label.QUEUED:active {
  color: #0960a5;
}
a.status-label.RUNNING:hover, a.status-label.PROCESSING:hover {
  color: #409343;
}
a.status-label.RUNNING:active, a.status-label.PROCESSING:active {
  color: #337636;
}
a.status-label.SUCCEEDED:hover {
  color: #0d0d0d;
}
a.status-label.SUCCEEDED:active {
  color: black;
}
a.status-label.SKIPPED:hover {
  color: #db8300;
}
a.status-label.SKIPPED:active {
  color: #b86d00;
}
a.status-label.FAILED:hover {
  color: #d2190b;
}
a.status-label.FAILED:active {
  color: #891008;
}

.status-section.CREATED h3, .status-section.QUEUED h3, .status-section.Warned h3 {
  color: #2196f3;
  background: #d2eafc;
}
.status-section.CREATED h3 .mat-icon-button.biosimulations-button:hover, .status-section.QUEUED h3 .mat-icon-button.biosimulations-button:hover, .status-section.Warned h3 .mat-icon-button.biosimulations-button:hover {
  color: #0c7cd5 !important;
}
.status-section.CREATED h3 .mat-icon-button.biosimulations-button:active, .status-section.QUEUED h3 .mat-icon-button.biosimulations-button:active, .status-section.Warned h3 .mat-icon-button.biosimulations-button:active {
  color: #0960a5 !important;
}
.status-section.RUNNING h3, .status-section.PROCESSING h3, .status-section.Passed h3 {
  color: #4caf50;
  background: #c8e6c9;
}
.status-section.RUNNING h3 .mat-icon-button.biosimulations-button:hover, .status-section.PROCESSING h3 .mat-icon-button.biosimulations-button:hover, .status-section.Passed h3 .mat-icon-button.biosimulations-button:hover {
  color: #409343 !important;
}
.status-section.RUNNING h3 .mat-icon-button.biosimulations-button:active, .status-section.PROCESSING h3 .mat-icon-button.biosimulations-button:active, .status-section.Passed h3 .mat-icon-button.biosimulations-button:active {
  color: #337636 !important;
}
.status-section.SUCCEEDED h3, .status-section.Skipped h3 {
  color: rgba(0, 0, 0, 0.8);
  background: #e6e6e6;
}
.status-section.SUCCEEDED h3 .mat-icon-button.biosimulations-button:hover, .status-section.Skipped h3 .mat-icon-button.biosimulations-button:hover {
  color: #0d0d0d !important;
}
.status-section.SUCCEEDED h3 .mat-icon-button.biosimulations-button:active, .status-section.Skipped h3 .mat-icon-button.biosimulations-button:active {
  color: black !important;
}
.status-section.SKIPPED h3 {
  color: #ff9800;
  background: #fff4e5;
}
.status-section.SKIPPED h3 .mat-icon-button.biosimulations-button:hover {
  color: #db8300 !important;
}
.status-section.SKIPPED h3 .mat-icon-button.biosimulations-button:active {
  color: #b86d00 !important;
}
.status-section.FAILED h3, .status-section.Failed h3 {
  color: #f44336;
  background: #fde2e0;
}
.status-section.FAILED h3 .mat-icon-button.biosimulations-button:hover, .status-section.Failed h3 .mat-icon-button.biosimulations-button:hover {
  color: #d2190b !important;
}
.status-section.FAILED h3 .mat-icon-button.biosimulations-button:active, .status-section.Failed h3 .mat-icon-button.biosimulations-button:active {
  color: #891008 !important;
}

/* snackbar */
/* select */
.mat-select-panel .mat-optgroup-label {
  height: auto !important;
}

.mat-optgroup-label,
.mat-option-text {
  font-size: 14px !important;
}

.multi-select-opt-group {
  margin-left: 1rem;
}

/* superscripts, subscripts */
sup,
sub {
  line-height: 0;
}

.centered-full-width-height {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* alerts, errors */
.valid {
  color: #2196f3;
}
.valid a {
  color: #2196f3;
  text-decoration: underline;
}

.error {
  color: #f44336;
}
.error a {
  color: #f44336;
  text-decoration: underline;
}

.warning {
  color: #ff9800; /* mat.get-color-from-palette($theme-accent); */
}
.warning a {
  color: #ff9800; /* mat.get-color-from-palette($theme-accent); */
  text-decoration: underline;
}

/* transitions */
.enter-from-right {
  transition: width ease-in-out 1s;
  overflow: clip;
  width: 0;
}

/* dialogs */
.mat-dialog-container {
  padding: 2rem;
}

@media screen and (max-width: 959px) {
  .mat-dialog-container {
    padding: 1rem;
  }
}
/* App Homepage Stylings */
.home-container {
  height: 100%;
  width: 100%;
  padding-top: 3rem;
}
@media (max-width: 768px) {
  .home-container {
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.home-description {
  padding: 4rem;
  font-size: 32px;
  display: flex;
}
.home-description.simulators {
  margin-right: 10rem;
  margin-left: 10rem;
}
.home-description.dispatch, .home-description.platform {
  width: fit-content;
}

.home-description-mobile {
  font-size: 22px;
  border: 3px solid inherit;
  border-radius: 4px;
}

.home-description,
.home-description-mobile {
  background-image: url("/assets/images/backgrounds/grey-3.png") !important;
  font-family: "Roboto", sans-serif;
  padding: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.about-section {
  margin-top: 3rem;
  padding: 3rem;
}

.home-bottom {
  padding: 5rem;
}

biosimulations-home biosimulations-home-subsection {
  padding-bottom: 6rem;
}

.data-table-parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
}

@media (max-width: 768px) {
  .data-table-card,
  .data-table-parent-container {
    transform: scale(0.75) !important;
    margin-top: -10rem !important;
  }
}
.data-table-container {
  margin-top: 3rem;
  overflow: hidden;
}
.data-table-container.platform {
  min-height: 1560px;
}
.data-table-container.simulators, .data-table-container.platform, .data-table-container.dispatch {
  padding-bottom: 0.5rem;
  height: fit-content;
  width: 100%;
}

mat-card-header.data-table-heading {
  background-color: var(--primary-color);
  border-radius: 4px 4px 0 0;
  color: white;
  font-weight: 700;
  font-size: 22px;
}

.data-table-card.platform, .data-table-card.dispatch-runs {
  width: 1657.7px;
}
.data-table-card.simulators {
  width: 1660px;
}

.table-buttons {
  display: flex;
  align-items: center;
  height: 15px;
  margin-left: auto;
}

.delete-simulations-dialog-container p {
  text-align: center;
}
.delete-simulations-dialog-container h1 {
  background-color: var(--warn-color);
  color: white !important;
  font-weight: 700;
}

/* Mobile Stylings */
.subsection-mobile {
  justify-content: center;
}

code {
  font-family: "Monaco", sans-serif;
}

biosimulations-home-section span {
  font-weight: 400;
}

/* Assorted Global Element Declarations */
mat-option {
  background-color: white;
}
mat-option:hover {
  background-color: var(--accent-lighter-color);
  color: var(--primary-color);
}

.biosimulations-button.run {
  background-color: var(--tertiary-darker-color) !important;
  color: white !important;
  font-weight: 700 !important;
}
.biosimulations-button.run:hover {
  background-color: var(--accent-darker-color) !important;
  transition: background-color 0.5s;
}

.form-parent-container {
  font-size: 32px;
  padding-top: 3rem;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.form-section-head {
  background-color: var(--primary-color) !important;
  font-weight: 700;
}

.form-section-head,
.form-section-subtitle {
  color: white !important;
}

/* MULTIPURPOSE REUSABLE STYLES */
.multipurpose-flat-button {
  border-radius: 5px;
  background-color: var(--accent-darker-color) !important;
  color: white !important;
  font-weight: 700 !important;
}
.multipurpose-flat-button:not(.rerun), .multipurpose-flat-button:not(.download) {
  margin-top: 5rem !important;
}
.multipurpose-flat-button:not(.download) {
  margin-left: auto !important;
}
.multipurpose-flat-button:hover {
  transition: background-color 0.3s ease !important;
  background-color: var(--tertiary-darker-color) !important;
}

.multipurpose-card-header {
  background-color: var(--primary-darker-color) !important;
  color: white;
  height: fit-content !important;
  width: auto !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-size: medium;
  border: 2px solid inherit;
  transition: background-color 0.1s ease-in-out;
  border-radius: 3px 3px 0 0 !important;
}
.multipurpose-card-header.sim-run {
  background-color: var(--tertiary-darker-color) !important;
}
.multipurpose-card-header:not(.panel-header) {
  border-radius: 4px 4px 0 0 !important;
}
.multipurpose-card-header.project {
  font-size: 24px;
}
.multipurpose-card-header.table-name {
  font-size: large;
}
.multipurpose-card-header:hover:not(.sidebar):not(.sim-run) {
  background-color: var(--accent-darker-color) !important;
  transition: background-color 2s ease-in-out;
}

.multipurpose-divider {
  width: 100% !important;
  height: 30px !important;
}

.alert {
  width: fit-content;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 14.5px;
}
.alert::before {
  content: "PLEASE NOTE: ";
  font-style: italic;
  color: var(--warn-color);
  padding-right: 3px;
}
.alert:hover {
  transition: font-weight 0.2s ease, color 0.2s;
}
.alert:hover, .alert::before {
  font-weight: 700;
}

.card-form-section-title {
  color: white !important;
  height: 100% !important;
  width: 100% !important;
  padding: 0.5rem !important;
  font-weight: 700 !important;
  background-color: var(--primary-darker-color) !important;
}

biosimulations-hover-open-menu button:hover {
  background-color: var(--accent-darker-color) !important;
  transition: background-color 0.3s ease !important;
  color: white !important;
  font-weight: 700;
}

/**
* Generated theme by Material Theme Generator
* https://material-theme-generator.travetto.io
* https://materialtheme.arcsine.dev/?c=eyJwYWxldHRlIjp7InByaW1hcnkiOnsibWFpbiI6IiMyMTk2ZjMiLCJsaWdodGVyIjoiI2JjZTBmYiIsImRhcmtlciI6IiMxNDc5ZWQifSwiYWNjZW50Ijp7Im1haW4iOiIjZmY5ODAwIiwibGlnaHRlciI6IiNmZmUwYjMiLCJkYXJrZXIiOiIjZmY3YjAwIn0sIndhcm4iOnsibWFpbiI6IiNmNDQzMzYiLCJsaWdodGVyIjoiI2ZjYzdjMyIsImRhcmtlciI6IiNlZjJjMjIifSwibGlnaHRUZXh0IjoiIzAwMDAwMCIsImxpZ2h0QmFja2dyb3VuZCI6IiNmYWZhZmEiLCJkYXJrVGV4dCI6IiNmZmZmZmYiLCJkYXJrQmFja2dyb3VuZCI6IiMyYzJjMmMifSwiZm9udHMiOlt7InRhcmdldCI6ImRpc3BsYXktNCIsImZhbWlseSI6IlJhbGV3YXkifSx7InRhcmdldCI6ImRpc3BsYXktMyIsImZhbWlseSI6IlJhbGV3YXkifSx7InRhcmdldCI6ImRpc3BsYXktMiIsImZhbWlseSI6IlJhbGV3YXkifSx7InRhcmdldCI6ImRpc3BsYXktMSIsImZhbWlseSI6IlJhbGV3YXkifSx7InRhcmdldCI6ImhlYWRsaW5lIiwiZmFtaWx5IjoiUmFsZXdheSJ9LHsidGFyZ2V0IjoidGl0bGUiLCJmYW1pbHkiOiJSYWxld2F5In0seyJ0YXJnZXQiOiJzdWJoZWFkaW5nLTIiLCJmYW1pbHkiOiJSYWxld2F5In0seyJ0YXJnZXQiOiJzdWJoZWFkaW5nLTEiLCJmYW1pbHkiOiJSYWxld2F5In0seyJ0YXJnZXQiOiJib2R5LTIiLCJmYW1pbHkiOiJSYWxld2F5In0seyJ0YXJnZXQiOiJib2R5LTEiLCJmYW1pbHkiOiJSYWxld2F5In0seyJ0YXJnZXQiOiJidXR0b24iLCJmYW1pbHkiOiJSYWxld2F5In0seyJ0YXJnZXQiOiJjYXB0aW9uIiwiZmFtaWx5IjoiUmFsZXdheSJ9LHsidGFyZ2V0IjoiaW5wdXQiLCJmYW1pbHkiOiJSYWxld2F5Iiwic2l6ZSI6bnVsbH1dLCJpY29ucyI6IkZpbGxlZCIsImxpZ2h0bmVzcyI6dHJ1ZX0=
*/
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/* Foreground Elements Light Theme Text Copied over from @angular/material. These use to be exported by the library, but are no longer. */
/* Dark Theme text */
/* Background config Light bg */
body {
  --primary-color: #2196f3;
  --primary-lighter-color: #9bcff9;
  --primary-darker-color: #1479ed;
  --primary-darkest-color: #0e5dc7;
  --primary-darkest-darker-color: #0a47a2;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(0, 0, 0, 0.8);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: #ff9800;
  --accent-lighter-color: #ffe0b2;
  --accent-darker-color: #ff7b00;
  --accent-darkest-color: #e66a00;
  --text-accent-color: rgba(0, 0, 0, 0.8);
  --text-accent-lighter-color: rgba(0, 0, 0, 0.8);
  --text-accent-darker-color: rgba(0, 0, 0, 0.8);
}

body {
  --tertiary-color: #4caf50;
  --tertiary-lighter-color: #80e27e;
  --tertiary-darker-color: #388e3c;
  --text-tertiary-color: rgba(0, 0, 0, 0.8);
  --text-tertiary-lighter-color: rgba(0, 0, 0, 0.8);
  --text-tertiary-darker-color: rgba(0, 0, 0, 0.8);
}

body {
  --quaternary-color: #951ed9;
  --quaternary-lighter-color: #d097f0;
  --quaternary-darker-color: #8d1cce;
  --text-quaternary-color: rgba(0, 0, 0, 0.8);
  --text-quaternary-lighter-color: rgba(0, 0, 0, 0.8);
  --text-quaternary-darker-color: rgba(0, 0, 0, 0.8);
}

body {
  --warn-color: #f44336;
  --warn-lighter-color: #fcc7c3;
  --warn-darker-color: #ef2c22;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.8);
  --text-warn-darker-color: #ffffff;
}

/* Theme Init */
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #ff9800);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #2196f3;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #2196f3;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff9800;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff9800;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff9800;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff9800;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 500);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.0067em);
}

.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-alternate .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-alternate .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-alternate .mat-mdc-option.mat-mdc-option-active, .theme-alternate .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.theme-alternate .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #2196f3);
}
.theme-alternate .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #ff9800);
}
.theme-alternate .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.theme-alternate .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-alternate .mat-pseudo-checkbox-full {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #2196f3;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #2196f3;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff9800;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff9800;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff9800;
}
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff9800;
}
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.theme-alternate .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.theme-alternate .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #303030;
  color: white;
}
.theme-alternate .mat-elevation-z0, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 500);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.0067em);
}

/* Specific component overrides, pieces that are not in line with the general theming */
/* Handle buttons appropriately, with respect to line-height */
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
  background-color: #2196f3;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/* Foreground Elements Light Theme Text Copied over from @angular/material. These use to be exported by the library, but are no longer. */
/* Dark Theme text */
/* Background config Light bg */
body {
  --primary-color: #2196f3;
  --primary-lighter-color: #9bcff9;
  --primary-darker-color: #1479ed;
  --primary-darkest-color: #0e5dc7;
  --primary-darkest-darker-color: #0a47a2;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(0, 0, 0, 0.8);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: #ff9800;
  --accent-lighter-color: #ffe0b2;
  --accent-darker-color: #ff7b00;
  --accent-darkest-color: #e66a00;
  --text-accent-color: rgba(0, 0, 0, 0.8);
  --text-accent-lighter-color: rgba(0, 0, 0, 0.8);
  --text-accent-darker-color: rgba(0, 0, 0, 0.8);
}

body {
  --tertiary-color: #4caf50;
  --tertiary-lighter-color: #80e27e;
  --tertiary-darker-color: #388e3c;
  --text-tertiary-color: rgba(0, 0, 0, 0.8);
  --text-tertiary-lighter-color: rgba(0, 0, 0, 0.8);
  --text-tertiary-darker-color: rgba(0, 0, 0, 0.8);
}

body {
  --quaternary-color: #951ed9;
  --quaternary-lighter-color: #d097f0;
  --quaternary-darker-color: #8d1cce;
  --text-quaternary-color: rgba(0, 0, 0, 0.8);
  --text-quaternary-lighter-color: rgba(0, 0, 0, 0.8);
  --text-quaternary-darker-color: rgba(0, 0, 0, 0.8);
}

body {
  --warn-color: #f44336;
  --warn-lighter-color: #fcc7c3;
  --warn-darker-color: #ef2c22;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.8);
  --text-warn-darker-color: #ffffff;
}