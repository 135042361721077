@use '@angular/material' as mat;
@import '../biosimulations-typography';

@include mat.core();
/* Foreground Elements Light Theme Text Copied over from @angular/material. These use to be exported by the library, but are no longer. */
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$dark-text: #000000;
$dark-primary-text: rgba($dark-primary-text, 0.8);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

/* Dark Theme text */
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

/* Background config Light bg */
$light-background: #fff;
$light-bg-darker-1: darken($light-background, 1%);
$light-bg-darker-3: darken($light-background, 3%);
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-darker-40: darken($light-background, 40%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
  tooltip: $dark-bg-alpha-12,
);

$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
  tooltip: $light-bg-alpha-12,
);

$theme-primary-main: #2196f3;
$theme-primary-lighter: #9bcff9;
$theme-primary-lightest: #d2eafc;
$theme-primary-lightestest: #e8f4fd;
$theme-primary-darker: #1479ed;
$theme-primary-darkest: #0e5dc7;
$theme-primary-darkest-darker: #0a47a2;

body {
  --primary-color: #{$theme-primary-main};
  --primary-lighter-color: #{$theme-primary-lighter};
  --primary-darker-color: #{$theme-primary-darker};
  --primary-darkest-color: #{$theme-primary-darkest};
  --primary-darkest-darker-color: #{$theme-primary-darkest-darker};
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: $theme-primary-main,
  lighter: $theme-primary-lighter,
  darker: $theme-primary-darker,
  200: $theme-primary-main,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$theme-accent-main: #ff9800;
$theme-accent-lighter: #ffe0b2;
$theme-accent-lightest: #fff4e5;
$theme-accent-darker: #ff7b00;
$theme-accent-darkest: #e66a00;

body {
  --accent-color: #{$theme-accent-main};
  --accent-lighter-color: #{$theme-accent-lighter};
  --accent-darker-color: #{$theme-accent-darker};
  --accent-darkest-color: #{$theme-accent-darkest};
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
  main: $theme-accent-main,
  lighter: $theme-accent-lighter,
  darker: $theme-accent-darker,
  200: $theme-accent-main,
  contrast: (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$theme-tertiary-main: #4caf50;
$theme-tertiary-lighter: #80e27e;
$theme-tertiary-lightest: #c8e6c9;
$theme-tertiary-darker: #388e3c;

body {
  --tertiary-color: #{$theme-tertiary-main};
  --tertiary-lighter-color: #{$theme-tertiary-lighter};
  --tertiary-darker-color: #{$theme-tertiary-darker};
  --text-tertiary-color: #{$dark-primary-text};
  --text-tertiary-lighter-color: #{$dark-primary-text};
  --text-tertiary-darker-color: #{$dark-primary-text};
}

$mat-tertiary: (
  main: $theme-tertiary-main,
  lighter: $theme-tertiary-lighter,
  darker: $theme-tertiary-darker,
  200: $theme-tertiary-main,
  contrast: (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  ),
);
$theme-tertiary: mat.define-palette($mat-tertiary, main, lighter, darker);

$theme-quaternary-main: #951ed9;
$theme-quaternary-lighter: #d097f0;
$theme-quaternary-lightest: #efdcfa;
$theme-quaternary-darker: #8d1cce;

body {
  --quaternary-color: #{$theme-quaternary-main};
  --quaternary-lighter-color: #{$theme-quaternary-lighter};
  --quaternary-darker-color: #{$theme-quaternary-darker};
  --text-quaternary-color: #{$dark-primary-text};
  --text-quaternary-lighter-color: #{$dark-primary-text};
  --text-quaternary-darker-color: #{$dark-primary-text};
}

$mat-quaternary: (
  main: $theme-quaternary-main,
  lighter: $theme-quaternary-lighter,
  darker: $theme-quaternary-darker,
  200: $theme-quaternary-main,
  contrast: (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  ),
);
$theme-quaternary: mat.define-palette($mat-quaternary, main, lighter, darker);

$theme-warn-main: #f44336;
$theme-warn-lighter: #fcc7c3;
$theme-warn-lightest: #fde2e0;
$theme-warn-darker: #ef2c22;

body {
  --warn-color: #{$theme-warn-main};
  --warn-lighter-color: #{$theme-warn-lighter};
  --warn-darker-color: #{$theme-warn-darker};
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: $theme-warn-main,
  lighter: $theme-warn-lighter,
  darker: $theme-warn-darker,
  200: $theme-warn-main,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
  )
);
$altTheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
  )
);
